<template>
  <div class="instructions">
    <div>
      <h3><svg-icon icon-class="wh_icon" />如何成为推广员？</h3>
      <p>
        所有的已注册会员，通过【商品详情页提示】、【“我的”→分佣推广】等入口，认证成为正品汇兼职分佣推广员。
      </p>
      <img src="@/assets/images/tg_png1.png" />
      <img src="@/assets/images/arrow_bottom.png" style="margin: 22px auto" />
      <img src="@/assets/images/tg_png2.png" alt="" />
    </div>
    <div>
      <h3><svg-icon icon-class="wh_icon" />如何获得推广佣金？</h3>
      <p>1、分享商城首页、活动页、专区页或者分享单个商品的专属链接；</p>
      <p>
        所有的已注册会员，通过【商品详情页提示】、【“我的”→分佣推广】等入口，认证成为正品汇兼职分佣推广员。
      </p>
      <img src="@/assets/images/tg_png3.png" alt="" />
    </div>
    <div>
      <h3><svg-icon icon-class="wh_icon" />如何查看我的推广订单？</h3>
      <p>【“我的”→分佣推广】，点击进入，页面底部点击查看【我的推广订单】</p>
      <img src="@/assets/images/tg_png4.png" alt="" />
    </div>
    <div>
      <h3><svg-icon icon-class="wh_icon" />如何查询佣金并提现？</h3>
      <p>
        1、【“我的”→分佣推广】，点击进入，查看累计赚佣/待结算佣金/已结算佣金；
      </p>
      <img src="@/assets/images/tg_png5.png" alt="" />
    </div>
    <div>
      <p>2、点击进入钱包，可提现钱包余额到银行卡；提现后1-3个工作日到账；</p>
      <img src="@/assets/images/tg_png6.png" alt="" />
    </div>
    <div>
      <p>3、在余额明细查看佣金到账记录和提现记录。</p>
      <img src="@/assets/images/tg_png7.png" alt="" />
      <img src="@/assets/images/arrow_bottom.png" style="margin: 22px auto" />
      <img src="@/assets/images/tg_png8.png" alt="" />
    </div>
    <div>
      <h3><svg-icon icon-class="wh_icon" />关于佣金结算？</h3>
      <p>1、推广员直接推广商品成功后，获得对应等级的佣金收入；</p>
      <img src="@/assets/images/tg_png9.png" alt="" />
    </div>
    <div>
      <p>2、根据推广员直接推广商品的累计实际购买金额评定等级；</p>
      <img src="@/assets/images/tg_png10.png" alt="" />
      <img src="@/assets/images/tg_png11.png" alt="" />
      <img src="@/assets/images/tg_png12.png" alt="" />
    </div>
    <p>
      3、推广佣金由基础佣金和等级佣金两部分组成，基础佣金按照商品成交价扣减商品成本及平台成本后的百分比计算；
    </p>
    <p>
      4、推广商品付款后T+7个工作日结算，结算之后20天内，实际结算金额将转入钱包余额。
    </p>
  </div>
</template>

<script>
export default {
  name: "instructions",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.instructions {
  padding: 76px 15px 30px;
  background: #fff;
  div {
    margin-bottom: 55px;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 25px;
    .svg-icon {
      margin-right: 8px;
    }
  }
  p {
    color: #666666;
    line-height: 24px;
    margin-bottom: 24px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
</style>
